import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAK6CV7T7Y4VVE-DWV0GKth2s06iUKYzqE",
    authDomain: "kabbadi-project.firebaseapp.com",
    projectId: "kabbadi-project",
    storageBucket: "kabbadi-project.appspot.com",
    messagingSenderId: "263453355220",
    appId: "1:263453355220:web:132550a55506b04b3f5f41"
};


firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };

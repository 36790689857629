import Scoreboard from './scoreboard/scoreboard';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div style={{
      position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: 'transparent'
    }}>
      <BrowserRouter>
        <Scoreboard />
      </BrowserRouter>
    </div>
  );
}

export default App;

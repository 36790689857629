import './scoreboard.css';
import { db } from "../helpers/firebase";
import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Commet } from 'react-loading-indicators';



const Scoreboard = () => {
    const [searchParams] = useSearchParams();

    const [scores, setScores] = useState([]);

    const reverseArr = (input) => {
        var ret = new Array;
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const checkForTeamSwitch = (s) => {
        setScores(curr => {
            if (curr.length === 0) {
                return s;
            }

            if (s[0].index !== curr[0].index) {
                return reverseArr(s);
            }

            return s;
        });
    }

    const startListner = () => {
        const uid = searchParams.get('id');

        if (!uid) return;

        return db
            .collection('users')
            .doc(uid)
            .collection('scoreboard')
            .onSnapshot((snapshot) => {
                const updatedScores = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                checkForTeamSwitch(updatedScores);
            }, (error) => {
                console.error('Error fetching scoreboard:', error);
            });
    }

    useEffect(() => {
        const unsub = startListner();

        return () => {
            if (unsub) unsub();
        };
    }, []);



    return (
        scores.length === 0 ? <div className='loader'><Commet color="#051942" size="medium" text="" textColor="" /></div> :
            <div className="scoreboard-container">
                <div className="scoreboard">
                    <div className='no-skew-container'>

                        <div className="team-name left">{scores[0].team}</div>
                        <div className="score-value-container">
                            <span className="score-value">{scores[0].score}</span>
                        </div>
                        <div className="live-indicator">Live</div>
                        <div className="score-value-container">
                            <span className="score-value">{scores[1].score}</span>
                        </div>
                        <div className="team-name right">{scores[1].team}</div>
                    </div>

                </div>
            </div>
    );
};

export default Scoreboard;
